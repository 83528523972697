@font-face {
  font-family: "VogieBold";
  src: local("VogieBold"),
   url("./fonts/Vogie-Bold.ttf") format("truetype");
  font-weight: bold;
  }

@font-face {
  font-family: "DIN";
  src: local("DIN"),
   url("./fonts/DIN2014-Regular.ttf") format("truetype");
}



tr.invoice-excluded td {
  text-decoration: line-through;
}

.highlight-fail {
  background-color: darksalmon;
  color: rgb(99, 1, 1) !important;
}

.highlight-ok {
  background-color: darkseagreen;
  color: rgb(32, 41, 16) !important;
}

body {
  margin: 0;
  font-family: 'DIN', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiTableCell-root, .MuiButtonBase-root, .MuiTablePagination-selectLabel, .MuiTablePagination-select, .MuiTablePagination-displayedRows   {
  font-family: DIN !important;
}

